/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Courses', route: { name: 'add-course' }, icon: 'ShieldIcon', isBookmarked: false },
      { title: 'Categories', route: { name: 'index-category' }, icon: 'SettingsIcon', isBookmarked: false },
      { title: 'Other Courses', route: { name: 'add-other-course' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Training Courses', route: { name: 'add-Training-course' }, icon: 'AlertTriangleIcon', isBookmarked: false },
      { title: 'Instructor', route: { name: 'add-instructor' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Cities And Location', route: { name: 'add-city' }, icon: 'PieChartIcon', isBookmarked: false },
      { title: 'Prices', route: { name: 'classical-course-price' }, icon: 'AirplayIcon', isBookmarked: false },
      { title: 'Diplomas ', route: { name: 'add-long-courses' }, icon: 'AlignLeftIcon', isBookmarked: false },
      { title: 'Scheduling', route: { name: 'schedule-classical-course' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Content', route: { name: 'course-content' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'Home Page', route: { name: 'add-upcoming-course' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'SEO', route: { name: 'seo-classical-course', params: { id: 1 } }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Certificates', route: { name: 'add-certificates', params: { id: 1 } }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Slider', route: { name: 'index-slider' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Hidden Course', route: { name: 'add-hidden-course' }, icon: 'HomeIcon', isBookmarked: false },
      { title: 'News', route: { name: 'add-article' }, icon: 'GridIcon', isBookmarked: false },
      { title: 'Post', route: { name: 'add-article' }, icon: 'BoldIcon', isBookmarked: false },
      { title: 'Client', route: { name: 'add-client' }, icon: 'BoldIcon', isBookmarked: false },
      { title: 'Events', route: { name: 'index-events' }, icon: 'BoldIcon', isBookmarked: false },
      { title: 'Speakers', route: { name: 'index-speakers' }, icon: 'CalendarIcon', isBookmarked: true },
      { title: 'Organiser', route: { name: 'index-organiser' }, icon: 'CalendarIcon', isBookmarked: false },
      { title: 'Accreditations ', route: { name: 'index-accreditation' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Near By', route: { name: 'add-country-pages' }, icon: 'CopyIcon', isBookmarked: false },
      { title: 'Funded', route: { name: 'all-ngos' }, icon: 'PieChartIcon', isBookmarked: false },
      { title: 'Gallery', route: { name: 'gallery-images' }, icon: 'MessageSquareIcon', isBookmarked: true },
      { title: 'Mail Collector', route: { name: 'index-mails' }, icon: 'CheckSquareIcon', isBookmarked: false },
      { title: 'Contact us', route: { name: 'contact-us' }, icon: 'DollarSignIcon', isBookmarked: false },
      { title: 'Career', route: { name: 'index-career' }, icon: 'ClipboardIcon', isBookmarked: false },
      { title: 'Settings', route: { name: 'add-user' }, icon: 'PlusIcon', isBookmarked: false },
      { title: 'Web Pages', route: { name: 'web_pages' }, icon: 'DropletIcon', isBookmarked: false },

    ],
  },
 
}
/* eslint-enable */
