<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none" >
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <dark-Toggler class="d-none d-lg-block" />
<search-bar class="d-none d-lg-block" />
    <b-navbar-nav class="nav align-items-center ml-auto">
     {{userData.name}}
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'
import SearchBar from './components/SearchBar.vue'

export default {
  components: {
    BLink,
    DarkToggler,
    SearchBar,
    // Navbar Components
    BNavbarNav,

    UserDropdown,
  },
    data() {
    return {
     userData:{}
    }
  },
    created() {
    // data
  this.userData=JSON.parse(localStorage.getItem("userData"))

  },
  
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
